<template>
  <v-form @submit.prevent="submitCode">
    <v-card>
      <v-card-title>
        You've got mail!
      </v-card-title>
      <v-card-text>
        <slot>
          We sent an e-mail to: {{ email }}.<br/>
          You can click the link in the e-mail or enter the code below.
        </slot>
      </v-card-text>
     <v-card-item>
       <v-row>
         <v-col cols="12">
           <v-text-field
               class="my-2"
               ref="otpTextField"
               v-model="modelValue"
               label="One-Time Code"
           />
         </v-col>
       </v-row>
     </v-card-item>
      <v-card-actions>
        <retriable-button
            ref="retriableButtonInput"
            @retry="resendCode($event)"
        />
        <v-spacer/>
        <v-btn
            :disabled="loading"
            :loading="loading"
            @click="submitCode"
            :text="$t('app.auth.verify')"
        />
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script setup lang="ts">
import RetriableButton from "~/components/buttons/retriable-button.vue";

interface Props {
  loading?: boolean;
  email?:string;
}

const props = withDefaults(defineProps<Props>(), {
  loading: undefined,
  email: undefined,
});

const otpTextField = ref<HTMLInputElement | undefined>();
const retriableButtonInput = ref<{restart: () => void}>();

const modelValue = defineModel<string | undefined>(undefined);


const emits = defineEmits(['retry', 'submit']);

const submitCode = () => {
  emits('submit', modelValue);
}

const resendCode = (event: any) => {
  emits('retry', event);
  nextTick(() => retriableButtonInput.value && retriableButtonInput.value.restart());
}

const focus = () => otpTextField.value && otpTextField.value?.focus();
const blur = () => otpTextField.value && otpTextField.value?.blur();

defineExpose({
  focus,
  blur,
});


</script>