<template>
  <v-row class="mt-10" v-if="!formWasSubmitted">
    <v-col cols="12" md="4" offset-md="4" v-if="!isReady">
      <app-loader/>
    </v-col>
    <v-col cols="12" md="4" offset-md="4" v-if="isReady">
      <v-form @submit.prevent="handleSignIn">
        <v-card>
          <v-card-title>
            Welcome back
          </v-card-title>
          <v-card-subtitle>
            Please enter your e-mail to log in.
          </v-card-subtitle>
          <v-card-item>
            <v-text-field
                variant="outlined"
                class="mt-3"
                ref="emailInput"
                v-model="email"
                type="email"
                label="Email"
                autocomplete="email"

            />
          </v-card-item>
          <v-card-item v-if="errors && errors.length > 0">
            {{ errors }}
          </v-card-item>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                :disabled="isLoading"
                :loading="isLoading"
                @click="handleSignIn"
                :text="$t('app.auth.login.btn')"
            />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
    <v-col cols="12" md="6" offset-md="3" class="text-center">
      <nuxt-link class="text-white text-disabled" :to="Routes.signUp">
        Need an account?
      </nuxt-link>
    </v-col>
  </v-row>
  <v-row class="mt-10" v-else>
    <v-col cols="12" md="4" offset-md="4">
      <one-time-password-card
          ref="otpInput"
          @retry="signInUsingEmail"
          @submit="submitOtpCode"
          v-model="otpCode"
          :email="email"
      />
    </v-col>
  </v-row>
</template>
<script lang="ts" setup>
import {onMounted, ref, watch} from 'vue';
import {useAccountsStore} from "~/lib/accounts";
import {storeToRefs} from "pinia";
import {navigateTo} from "#app";
import {Routes} from "~/lib/navigation";
import {useUrlSearchParams} from '#imports';
import OneTimePasswordCard from "~/components/one-time-password-card.vue";


const isLoading = ref<boolean>(false);
const formWasSubmitted = ref<boolean>(false);
const email = ref('');
const errors = ref<any[]>([]);
const emailInput = ref<HTMLInputElement>();
const otpInput = ref<HTMLInputElement>();

const supabase = useSupabaseClient();
const queryParams = useUrlSearchParams<{
  redirect?: string
}>('history');

const {isLoggedIn} = storeToRefs(useAccountsStore());

const isReady = ref(false);

const {
  public: {siteUrl}
} = useRuntimeConfig();


const signInUsingEmail = async () => {
  const {error} = await supabase.auth.signInWithOtp({
    email: email.value,
    options: {
      shouldCreateUser: true,
      emailRedirectTo: `${siteUrl}/confirm?redirect=${queryParams.redirect ?? '/app'}`
    }
  });

  if (error) {
    errors.value.push(error);
    return;
  }
}


const handleSignIn = async () => {
  //TODO: Add timer so that we can attempt to send this again if no email was received.
  isLoading.value = true;
  await signInUsingEmail()
  formWasSubmitted.value = true;
  emailInput.value && emailInput.value.blur();
  setTimeout(() => otpInput.value && otpInput.value.focus(), 150);
  isLoading.value = false;
}


const redirectIfLoggedIn = async () => {
  if (isLoggedIn.value) {
    return navigateTo(queryParams.redirect ?? Routes.app.home);
  }
  isReady.value = true;
}

onMounted(async () => {
  await redirectIfLoggedIn();
  emailInput.value && emailInput.value.focus();
});

watch(isLoggedIn, redirectIfLoggedIn);


//for OTP
const otpCode = ref<string | undefined>(undefined);

const submitOtpCode = async () => {
  if (!email.value || !otpCode.value) {
    return;
  }

  await supabase.auth.verifyOtp({
    email: email.value,
    token: otpCode.value,
    type: 'email'
  })
}
</script>